/* You can add global styles to this file, and also import other style files */
@import '@xpo-ltl/ngx-ltl-core/styles/variables';

.divider
{
    &-vertical {
        margin: 0px $xpo-contentSpacing * 2;
        border-left: 1px solid #bdbdbd;
        content: "";
    }

    &-horizontal {
        margin: $xpo-contentSpacing 0px;
        border-top: 1px solid #bdbdbd;
        content: "";
        width: 100%;
    }
    
}

/* Fixed base container not being full width*/
.xpo-TabDrawer-contentWrapper.mat-drawer-content {
    padding: 0!important;
}

.xpo-ContextualHeader {
    height: 48px!important
}

.mat-drawer-container{
    background-color: white !important;
}

/* Makes the Mat Slide Toggle XPO blue instead of default green */
mat-slide-toggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $xpo-blue--350;
  border: 1px solid $xpo-blue--800;
}
mat-slide-toggle.mat-slide-toggle.mat-checked.mat-disabled { 
  .mat-slide-toggle-bar {
    background-color: $xpo-blue--200;
    border: 1px solid $xpo-blueGrey--700; 
  }

  .mat-slide-toggle-thumb {
    box-shadow: 0 0 0 1px $xpo-blueGrey--700;
  }
}
mat-slide-toggle.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar {
  // opacity: 50%;
}

mat-slide-toggle.mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb

.mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
    // background-color: $xpo-blue--350 !important
}

/* Fix Contextual Header action not having right padding*/
.xpo-HeaderActions {
    padding-right: $xpo-contentSpacing
}

/* Fix for the XPO Auth user profile picture icon being justified left instead of center */
.xpo-Header-utilities-actions>* {
    justify-content: center;
}

/* Fix for the Master Detail toggle button height not being full cell */
.ag-grid .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    height: 100% !important;
}

// Fix for Grid Left Pinned Border Not Correct Width
.ag-cell-last-left-pinned, .ag-pinned-left-header {
  border-right: solid 3px $xpo-grey--300!important;
}

// Fix for Grid Right Pinned Border Not Correct Width
.ag-cell-first-right-pinned, .ag-pinned-right-header {
  border-left: solid 3px $xpo-grey--300!important;
}

/* Fix for the Floating Filter pinned header color */
.ag-pinned-left-header, .ag-pinned-right-header {
  background-color: #f3f3f3;
}

/* Makes all grids have alternating row colors */
.ag-row {
  &.ag-row-odd:not(.ag-row-selected):not(.ag-row-hover) {
    // background-color: $xpo-grey--80;
  }
}

/* Fix for row grouping selection not being full height of cell */
.ag-grid .ag-ltr .ag-cell {
    height: 100%;
}

/* XpoNgxGridColumns.SELECTION does now fire off selection change event so we must use XpoNgxGridColumns.SELECTION_WITH_SELECT_ALL
   Update ColDefs to { ...XpoNgxGridColumns.SELECTION_WITH_SELECT_ALL, headerClass:"hide-select-all-header" } to hide header checkbox */
.hide-select-all-header {
    content-visibility: hidden;
    border-right: solid 1px #d8d8d8;
    background-color: #f6f6f6;
}  

// Override  for EnumFloatingFilter select not being full witdh of cell
.xpo-GridMultiSelect-floatingFilter .mat-form-field {
  width: 100%;
}

/* Remove minimum grid height */
.xpo-NgxGrid-gridWrapper ag-grid-angular {
    min-height: 0px !important;
}

/* Switch gray "Active" on dropdowns to 50% hover color*/
.mat-option.mat-active {
    background-color: rgba($xpo-blue--200, 0.5);
}

/* Handles fixed for prefix and suffix as well as disabled coloring */
.mat-form-field {
    .inputPrefix {
      margin: 0 $xpo-contentSpacing * 0.5;
    }
    .inputSuffix {
      margin: 0 $xpo-contentSpacing * 0.5;
      margin-left: 0
    }
  
    .inputRight {
      text-align: right;
    }
  
    mat-label.multiValue {
      border-bottom: 1px solid $xpo-grey--950;
      padding-bottom: 2px;
    }
  
    &-disabled {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          background-color: $xpo-brand-medium-grey--200;
        }
      }
    }
}

.xpo-TileRibbon-inner-container {
    border: 1px solid #d8d8d8!important;
}

.xpo-Tile-header-title {
	font-size: 1.571rem!important;
 	line-height: 1.571rem!important;
}

.inline-code {
    font-family: monospace;
    background-color: #f1f1f1;
    padding: 2px 4px;
    border-radius: 4px;
    color: #333;
}

// Make input and select both the same height
input, mat-select {
  height: 20px;
}

// Add Flex to AG Grid
.xpo-AgGrid {
  flex: 1;
}

.xpo-ngxGrid-customColumns {
  background-color: #f6f6f6;

  &:hover{
    background-color: #f6f6f6!important;
  }
}

/* Fix XPO Header Color */
.xpo-Header {
  background: white;
}

// Fix SVG Icons not taking the xpoSmallIcon styles
.xpo-Icon--small, .xpo-Icon--small .xpo-Icon, .xpo-Icon--small .mat-icon {
  font-size: 1.285rem!important;
  width: 18px!important;
  height: 18px!important;
}

.grid-cell {
  &-editable {
    background-color: $xpo-yellow--200;
  }

  &-align-right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-red {
    color: $xpo-red--150;
    font-weight: $xpo-fontWeight--bold;
  }
}

// Fix for SelectionWithSelectAll header cell not displaying
.ag-header-select-all {
  display: flex !important;
}

// Start - Fix Ngx Core Tab overflow issue
.xpo-AlternateTabs .mat-tab-labels::after {
  flex: 1;
}
.xpo-PillTabGroup .mat-tab-labels {
  &::before, &::after {
    display: none;
  }
}
// End - Fix Ngx Core Tab overflow issue

// AG Set Filter Height Overrides
.xpo-AgGrid .ag-set-filter-list, .xpo-AgGrid .ag-filter-no-matches {
  height: 229px!important;
}

/* Easy Global Flex Gap */
.flex-gap {
  &-4 {
    gap: $xpo-contentSpacing * 0.25;
  }
  &-8 {
    gap: $xpo-contentSpacing * 0.5;
  }
  &-16 {
    gap: $xpo-contentSpacing;
  }
  &-24 {
    gap: $xpo-contentSpacing * 1.5;
  }
  &-32 {
    gap: $xpo-contentSpacing * 2;
  }
  &-48 {
    gap: $xpo-contentSpacing * 3;
  }
  &-64 {
    gap: $xpo-contentSpacing * 4;
  }
}
/* Grid toolbar styling overrides */
xpo-grid-toolbar {
  button, mat-slide-toggle {
    align-items: center!important;
    background: transparent!important;
    border: 0!important;
    border-radius: 0!important;
    display: flex!important;
    flex-direction: row!important;
    gap: 8px!important;
    justify-content: space-between!important;
    align-items: center!important;
    height: 100%!important;
    width: auto!important;
    padding: 0 12px!important;

  
    xpo-icon, xpo-busy-loader {
      display: block!important;
      width: 20px!important;
      height: 20px!important;

      &::before{
        width: 20px!important;
        height: 20px!important;
        font-size: 20px!important;
      }
    }
  }

  .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  xpo-grid-toolbar-actions-group div { //For some reason the actions-group nests all elements in a div and the settings does not, so we need the added div tag here
    > *:not(xpo-grid-toolbar-dynamic-more) ~ * {
      border-left: 1px solid $xpo-grey--300!important;
    }

    > *:not(xpo-grid-toolbar-dynamic-more):last-child {
      border-right: 1px solid $xpo-grey--300!important;
    }
  }

  xpo-grid-toolbar-settings-group {
    > * ~ * {
      border-left: 1px solid $xpo-grey--300!important;
    }

    > *:first-child {
      border-left: 1px solid $xpo-grey--300!important;
    }
  }
}

/** In-Line Snackbar Style Fixes */
xpo-snack-bar {
  .xpo-SnackBar-container {
    align-items: center;
    gap: $xpo-contentSpacing;

    xpo-icon {
      padding: 0!important;
      margin-right: 0!important;
      overflow: visible;
    }

    .xpo-SnackBar-text {
      font-size: $xpo-fontSize--large;
    }
  }
}